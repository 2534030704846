import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Body, H3, HeaderH5Style } from '../../util/typography';
import { arrowRightGreenDark, arrowRightGreenRollover } from '../../util/icons';

const Title = styled(H3)`
    color: ${colors.white};
    text-align: center;
`;

const RelatedContentContainer = styled.div`
    border-bottom: 15px solid ${colors.mulberry};
    background: linear-gradient(96deg, ${colors.brandGreen} 47.42%, #5FC323 100%);
    img {
        width: 20px;
    }
`;

const RCContent = styled.div`
    display: flex;
    padding: 29px 20px 48px 20px;
    flex-direction: column;
    align-items: center;
    gap: 37px;
    max-width: 1032px;
    margin: 0 auto;
    ${respondTo.md`
        padding: 55px 30px 75px 30px;
        gap: 32px;
    `}
`;

const TextIcon = styled(Link)`
    cursor: pointer;
    display: flex;
    padding: 20px 65px 20px 20px;
    align-items: center;
    gap: 30px;
    flex: 1 0 0;
    border-radius: 6px;
    justify-content: space-between;
    height: auto;
    background: ${colors.white};
    text-decoration: none;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 15px;
        background: url(${arrowRightGreenDark});
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-position: center left;
    }
    &:hover {
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.35);
        [class*='__LinkTitle']{
            color: ${colors.greenRollOver};
        }
        &:after {
            background: url(${arrowRightGreenRollover});
            background-repeat: no-repeat;
            animation-duration: 0.3s;
            animation-name: arrowRight-animation;
            animation-fill-mode: both;
        }
    }
    @keyframes arrowRight-animation {
        0% {
            background-position: center 50%, left;
        }

        50% {
            background-position: calc(100% + 20px) center, center;
        }

        50.001% {
            background-position: calc(0% - 20px) center, center;
        }

        100% {
            background-position: center 50%, right;
        }
    }
`;

const TextArea = styled.div`
    color: ${colors.charcoal};
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const LinkTitle = styled.div`
    color: ${colors.greenDark};
    ${HeaderH5Style};
`;

const LinkButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 30px;
    align-self: stretch;
    flex-direction: column;
    align-items: stretch;
    ${respondTo.md`
        flex-direction: row;
        gap: 20px;
    `}
`;

const RelatedContent = ({className, title, links}) => {
    return (
        <RelatedContentContainer className={className}>
            <RCContent>
                {title && <Title>{title}</Title>}
                <LinkButtons>
                    {links && links.map((link, i) => 
                        <TextIcon to={link.to} key={i}>
                            <TextArea>
                                <Body>{link.eyebrow}</Body>
                                <LinkTitle>{link.title}</LinkTitle>
                            </TextArea>
                        </TextIcon>
                    )}
                </LinkButtons>
            </RCContent>
        </RelatedContentContainer>
)};
export default RelatedContent;
