import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';

const TableContent = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid ${colors.pebble};

        
    & > * {
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        padding: 12px 20px;
        background: ${colors.white};
        color: ${colors.charcoal};
        ${({type}) => type !== 'keytruda' && type !== 'lenvima' && type !== 'default-cloud' ? `
            border-bottom: 1px solid ${colors.mist};
        ` : ''};
        &:last-child{
            border-radius: 0 0 8px 8px;
            border-bottom: 0;
        }
        ${({type}) => type === 'keytruda' || type === 'lenvima' ? 
            `&:nth-child(2n + 1){
                background: ${colors.cloud};
            }` : type === 'default-cloud' ? `
                &:nth-child(2n){
                    background: ${colors.cloud};
                }
        ` : ''};
        &:first-child{
            border-radius: 8px 8px 0 0;
            ${({type}) => type === 'lenvima' || type === 'keytruda' ? `
                font-weight: 700;
            `: ''};
        }
        ${({type}) => type !== 'default' ? 
        `&:first-child{
            border-radius: 8px 8px 0 0;
            color: ${colors.ink};
            background: ${type === 'lenvima' ? colors.mulberryBackground : type === 'keytruda' ? colors.greenBackground : colors.white};
        }` : ``}

        
    }



    
`;

const Table = ({children, className, type='keytruda'}) => {
    return (
        <TableContent className={className} type={type}>
            {children}
        </TableContent>
)};
export default Table;
