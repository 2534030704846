import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';

const TextIcon1ColumnContent = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    flex: 1 0 0;
    img {
        width: 50px;
        height: 50px;
        //Added to left align icons on mobile
        margin-left: -7px;
    }
    ${respondTo.md`
        flex-direction: row;
        img {
            margin-left: 0;
        }
    `}
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    color: ${colors.charcoal};
    height: 100%;
    justify-content: center;
    li::marker, li::before {
        background-color: ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.charcoal};
    }
`;

const Title = styled.div`
    color: ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.charcoal};
`;

const TextIcon1Column = ({children, className, imgSrc, imgAlt = '', type, noTitle, titleType}) => {
    return (
        <TextIcon1ColumnContent className={className}>
            {imgSrc && <img src={imgSrc} alt={imgAlt}/>}
            <Column type={type}>
                {!noTitle && <Title type={titleType || type}>{Array.isArray(children) ? children[0] : children}</Title>}
                {noTitle ? children : children[1] && children.slice(1)}
            </Column>
        </TextIcon1ColumnContent>
)};
export default TextIcon1Column;
