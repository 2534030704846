import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';

export const SubsectionContent = styled.div`
    padding: 30px 20px 0 20px;
    display: flex; 
    flex-direction: column;
    gap: 30px;
    color: ${colors.charcoal};
    max-width: 1032px;
    margin: 0 auto;
    ${respondTo.md`
        padding: 30px 30px 0 30px;
    `}
`;

const Subsection = ({children, className}) => {
    return (
        <SubsectionContent className={className}>
            {children}
        </SubsectionContent>
)};
export default Subsection;
