import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { plusIconGreen, plusIconDarkGreen, plusIconMulberry, minusBrandGreen, minusGreenDark, minusMulberry } from '../../util/icons';
import { AccordionTitleStyle } from '../../util/typography';

const AccordionContainer = styled.details`
    border: ${({type}) => type === 'keytruda' ? `1px solid ${colors.brandGreen}` : type === 'lenvima' ? `1px solid ${colors.mulberry}` : `1px solid ${colors.greenDark}`};
    background: ${colors.white};
    border-radius: 5px;
    &[open] > summary::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background: ${colors.pebble};
    }
    &:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
        [class*='__AccordionTitle'] img {
            background: ${colors.mist};
            border-radius: 50%;
        }
    }
    ${respondTo.md`
        &[open] > summary::after {
            left: 30px;
            width: calc(100% - 60px);
        }
    `}
`;

const AccordionTitle = styled.summary`
    cursor: pointer;
    position: relative;
    &::marker {
        content: none;
    }
    &::-webkit-details-marker {
        display: none;
    }
    img {
        width: 20px;
        height: 20px;
        padding: 3px;
    }
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    ${AccordionTitleStyle};
    color: ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.greenDark};
    ${respondTo.md`
        padding: 20px 30px;
        gap: 35px;
    `}
`;

const AccordionContent = styled.div`
    padding: 20px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    ${respondTo.md`
        padding: 20px 30px;
    `}
`;

const AccordionWarning = styled.p`
    ${AccordionTitleStyle};
    font-weight: 400;
    font-style: italic;
`;

const Accordion = ({children, className, title, type, warningMsg = '[see Warnings and Precautions]'}) => {

    const [isOpen, setIsOpen] = useState(false);
    const detailsRef = useRef(null);

    useEffect(() => {
        detailsRef.current.addEventListener("toggle", (event) => {
            setIsOpen(event.target.open);
        });
        return function cleanup() {
            detailsRef.current.removeEventListener("toggle", (event) => {
                setIsOpen(event.target.open);
            });
        };
    }, []);

    return (
        <AccordionContainer className={className} type={type} ref={detailsRef}>
                <AccordionTitle type={type}>
                    <img src={isOpen? type === 'keytruda' ? minusBrandGreen : type === 'lenvima' ? minusMulberry : minusGreenDark : type === 'keytruda' ? plusIconGreen : type === 'lenvima' ? plusIconMulberry : plusIconDarkGreen} alt=''/>
                    {title}
                </AccordionTitle>
                <AccordionContent>
                    {warningMsg && <AccordionWarning>
                        {warningMsg}
                    </AccordionWarning>}
                    {children}
                </AccordionContent>
        </AccordionContainer>
)};
export default Accordion;
