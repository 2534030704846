import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Body } from '../../util/typography';

const SectionContainer = styled.section`
    display: flex;
    justify-content: center;
    background: ${({bgColor}) => bgColor === 'cloud' ? colors.cloud : colors.white};
    padding-bottom: 40px;
`;

const SectionContent = styled.div`
    max-width: 1032px;
    width: 100%;
    keytitle {
        color: ${colors.brandGreen};
    }
    lentitle {
        color: ${colors.mulberry};
    }
    [class*='__SubsectionContent'] ~ [class*='__SubsectionContent']{
        position: relative;
        margin-top: 30px;
        &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 20px;
            width: calc(100% - 40px);
            height: 1px;
            background: ${colors.pebble};
            ${respondTo.md`
                left: 30px;
                width: calc(100% - 60px);
            `}
       }
    }
`;

const SectionIntro = styled.div`
    padding: 30px 20px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: ${colors.charcoal};
    ${respondTo.md`
        padding: 40px 30px 0 30px;
    `}
`;

const SectionTitle = styled(Body)`
    width: fit-content;
    position: relative;
    padding: 10px 20px;
    text-transform: uppercase;
    text-align: center;
    background: ${colors.white};
    &::before{
        content: '';
        position: absolute;
        inset: 0;
        border: 2px solid ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.charcoal};
        border-radius: 4px;
        // ${({type}) => type !== 'keytruda' && type !== 'lenvima' && `
        //     background: linear-gradient(to right, ${colors.brandGreen}, 50%, ${colors.mulberry}) border-box;
        //     -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        //     -webkit-mask-composite: xor;
        // `}
    }
`;

const Section = ({title, children, bgColor, noIntro, className, type, ...props}) => {
    return (
        <SectionContainer bgColor={bgColor} className={className} {...props}>
            <SectionContent>
                {!noIntro && <SectionIntro>
                    {title && <SectionTitle type={type}>{title}</SectionTitle>}
                    {children[0]}
                </SectionIntro>}
                {children && !noIntro && children[1] && children.slice(1)}
                {noIntro && children}
            </SectionContent>
        </SectionContainer>
)};
export default Section;