export const jobCodes = {
    0: {
        // MT
        id: 0,
        jobCode: 'US-KLX-00429 09/24',
    },
    1: {
        // RCC
        id: 1,
        jobCode: 'US-KLR-00584 09/24',
    },
    2: {
        // ENDO
        id: 2,
        jobCode: 'US-KLE-01326 09/24',
    },
};
